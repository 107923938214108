import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { PrintService } from 'src/app/services/print.service';
import { UserService } from 'src/app/services/user.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Produit } from '../all-produit/Produit.model';
import { CommandeService } from 'src/app/services/commande.service';
import { SharedService } from 'src/app/services/shared.service';
import { DetailsComponent } from '../commande-attente/details/details.component';
import { Router } from '@angular/router';
import { ObservationBankiComponent } from 'src/app/observation-banki/observation-banki.component';

@Component({
  selector: 'app-commande-traite',
  templateUrl: './commande-traite.component.html',
  styleUrls: ['./commande-traite.component.scss']
})
export class CommandeTraiteComponent implements OnInit{
  page = 1;
  count = 0;
  pageSize = 3;
  pageSizes = [5, 10, 15];
  public isObject= false
  title = '';
  public  commandeAttente:Produit[] = []; 
  public commandeTrouvee: any
  public commandeReferenceAchercher= ''
     // commandes: any[] = []; 
public commande: any
dataSource = new MatTableDataSource<PeriodicElement>(null);
subscription: Subscription;
displayedColumns: string[] = ['dateCommande', 'montantTotal','status','Action','details'];

@ViewChild(MatPaginator) paginator: MatPaginator;
table: DatatableComponent
@ViewChild(MatSort, { static: false }) sort: MatSort;


public settings: Settings;
  
constructor(
      private commandeService: CommandeService,private userService:UserService,
      public sharedService: SharedService, 
      public print: PrintService, 
      private dialog: MatDialog,
      public appSettings:AppSettings,
      private _router: Router,

  ) {
    this.settings = this.appSettings.settings; 
}

ngOnInit(): void {
  this.subscription =  timer(0, 3000).subscribe(res => {
    if(this.commandeReferenceAchercher == ''){
     this.getCommandTraiteForBankitruck()
    }
  });

}

getCommandTraiteForBankitruck(){
  const params = this.getRequestParams(this.title, this.page, this.pageSize);
   this.commandeService.getCommandTraiteForBankitruck(params.page).subscribe((res : any)=> {
        this.commandeAttente = res.docs;
        this.count = res.totalDocs;
        this.page = params.page;
   });
  }

  retourBanki(id: any,approuve:any){
    this.dialog.open(ObservationBankiComponent, {
      data: {id: id , user: this.userService.getUserDetails().name ,approuve:approuve, status: "commandetraité" },
      width: '600px',
      height: '600px',
    })
  }


  getRequestParams(searchTitle: string  , page: number, pageSize: number): any {
    let params: any = {};
    if (searchTitle) {
      params[`title`] = searchTitle;
    }
    if (page) {
      params[`page`] = page;
    }
    if (pageSize) {
      params[`size`] = pageSize;
    }
    return params;
  }
  chercherUneCommandeParSaReference(){
    if(this.commandeReferenceAchercher== ''){
      this.getCommandTraiteForBankitruck()
    }
    else{
    
      const obj={
        reference: this.commandeReferenceAchercher
      }
      this.commandeService.chercherUneCommandeParSaReference(obj)
      .subscribe((data: any)=>{
        this.isObject= true
        this.commandeAttente = data
      })
    }
  }
  handlePageChange(event: number): void {
    this.page = event;
    this.commandeService.getCommandTraiteForBankitruck(this.page).subscribe((res : any)=> {
      this.commandeAttente = res.docs;
      this.count = res.totalDocs;
    });
  }



  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.getCommandTraiteForBankitruck();
  }

  public onPageChanged(event){
    this.page = event;
    this.getCommandTraiteForBankitruck();
    if(this.settings.fixedHeader){      
        document.getElementById('main-content').scrollTop = 0;
    }
    else{
        document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
    }
  } 


   getAllDetailCommande() {
    this.commandeService.AllCommandeAttenteDistributeur(this.userService.getUserDetails()._id).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource <PeriodicElement> (data); 
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
     this.sharedService.tableauProduitParCommandeId= data
 
      return data
    })}
    routeTo(id: any){
      sessionStorage.setItem("clientId", id)
      this._router.navigateByUrl('/profile/user-info/'+id)
    }
voirDetailCommande(id){
  this.sharedService.panierId= id
   this.dialog.open(DetailsComponent, {
    width: '1200px',
    height: '200px',
    data: {id: id}
   })
}



}
export interface PeriodicElement {
  dateCommande: string
  montantTotal: string
  status: string
  Action: string
  details: string
  }


