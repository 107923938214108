import { Menu } from './menu.model';

export const verticalMenuItems = [ 
    new Menu (1, 'Tableau de bord', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Passport', '/passport', null, 'view_module', null, false, 0),  
    new Menu (7, 'Localisation', null, null, 'view_module', null, true, 0), 
    new Menu (7, 'Pays', "/allPays", null, 'view_module', null, false, 7), 
    new Menu (7, 'Region', "/allRegion", null, 'view_module', null, false, 7), 
    new Menu (7, 'Zone', "/allCommune", null, 'view_module', null, false, 7), 
    new Menu (8, 'Users Bank', '/all_user', null, 'supervisor_account', null, false, 0), 
    new Menu (9,"Dossiers encours d'exécution", '/commandeAttente', null, 'view_module', null, false, 0), 
    new Menu (10, 'Dossiers clôturés', '/commandeTraite', null, 'view_module', null, false, 0), 


    
]

export const horizontalMenuItems = [ 
    new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Produit', '/produit', null, 'view_module', null, false, 0), 
    new Menu (3, 'Categorie', '/categorie', null, 'view_module', null, false, 0), 
    new Menu (8, 'Users Bank', '/all_user', null, 'supervisor_account', null, false, 0), 
    new Menu (9,"Dossiers encours d'exécution", '/commandeAttente', null, 'view_module', null, false, 0), 
    new Menu (10, 'Dossiers clôturés', '/commandeTraite', null, 'view_module', null, false, 0), 


   
]