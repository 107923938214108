
import { SharedService } from 'src/app/services/shared.service';
import { CommandeService } from 'src/app/services/commande.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Subscription, timer, Observable } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { PrintService } from 'src/app/services/print.service';
import { UserService } from 'src/app/services/user.service';
import { ValiderDossierComponent } from 'src/app/valider-dossier/valider-dossier.component';
import { RejeterDossierComponent } from 'src/app/rejeter-dossier/rejeter-dossier.component';
import { ObservationBankiComponent } from 'src/app/observation-banki/observation-banki.component';
import { AddCategorieComponent } from 'src/app/add-categorie/add-categorie.component';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  public commandeAttente: []

  commandes: any[] = []; 
public commande: any
dataSource = new MatTableDataSource<PeriodicElement>(null);
subscription: Subscription;
displayedColumns: string[] = ['dateCommande', 'montantTotal','Action','details'];
statutForAcommande: Observable<any>
@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort, { static: false }) sort: MatSort;

public settings: Settings;
private statut: []
constructor(
  private commandeService: CommandeService,

  private userService:UserService,
  public dialogRef: MatDialogRef<DetailsComponent>,
 @Inject(MAT_DIALOG_DATA) public data: any,
  public sharedService: SharedService, 
  public print: PrintService, 
  private dialog: MatDialog,
  public appSettings:AppSettings
) {
this.settings = this.appSettings.settings; 
}

ngOnInit(): void {

 this.subscription =  timer(0, 3000).subscribe(res => {
 this.getCommandesAttente();
 console.log("data",this.data.id);

});
}

retourBanki(id: any,approuve:any,idCommande:any){
  this.dialog.open(ObservationBankiComponent, {
    data: {idCommande:this.data.id,id: id , user: this.userService.getUserDetails().name,approuve:approuve },
    width: '600px',
    height: '600px',
   
  })
}




// public getPositionStatus(id: any) {
//   return this.checkPositionStatusByCommande(id).pipe(
//     map((response) => response)
//   );
// }

getCommandesAttente(){
 
  this.commandeService.AllCommandeAttenteDistributeur(this.data.id).subscribe(res => {
    console.log(this.data.id)
    this.commande = res[0].panier;
 
  });
 
 }

ValiderDossierCommande(id,idCommande){
  this.sharedService.panierId= id
   this.dialog.open(ValiderDossierComponent, {
    data: {id: id,idCommande: idCommande}
   })
  }
  RejeterDossierCommande(id,idCommande){
    this.sharedService.panierId= id
     this.dialog.open(RejeterDossierComponent, {
     
      data: {id: id,idCommande: idCommande}
     })
    }

livrerCommandeManquantess(commandeId: any, panierId: any){
 const obj={
   commandeId: commandeId,
   produit_panier_Id: panierId,
 }

 
}


}
export interface PeriodicElement {
dateCommande: string
montantTotal: string
Action: string
details: string
}
