import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ProduitService } from 'src/app/services/produit.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {

 
  etatPadding: boolean = true;
  _id: any;
  quantite: any;
  zone: any;
  livraison: any;
  user: any;
  livraisons = 0;
  categories: any[] = [];
  constructor( public dialogRef: MatDialogRef<AddRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private fb: FormBuilder,private produitService: ProduitService, private userService: UserService, private _snackBar: SnackBarService, private router: Router, private route: ActivatedRoute) { }
 
 
    ngOnInit(): void {
  this.getUsers();
 
  console.log("bella",this.data.id);
  }
  registerForm = this.fb.group({
    role: ['', []],
    user_id: ['', []]
  });

  onSubmit(){
    this.etatPadding = false;
   this.user_id.setValue(this.user._id);
  
    this.userService.addRole(this.registerForm.value).subscribe(res => {
      console.log("groupe",this.registerForm.value);
      this._snackBar.openSnackBar("Enregistrement Reuissi!!", "FERMER");
      
      this.etatPadding = true;
      this.dialogRef.close();
    });

  
  }
  getUsers(){
    this.userService.getUserById(this.data.id).subscribe(res => {
      this.user = res;
     console.log("user bella",this.user);
     
    })
  }
  selectZone(event: any){
    this.livraisons = event.target.value;
   
  }
  OnClose(){
    this.dialogRef.close();
  }
  
  get role(){
    return this.registerForm.get('role');
  }
  get user_id(){
    return this.registerForm.get('user_id');
  }
}
